<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a href="https://lk.exportcenter.ru/ru/main">Главная</a></li>
      <li class="breadcrumb-item"><a href="https://lk.exportcenter.ru/ru/educational-services">Образовательные сервисы</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ title }}</li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: ['title']
}
</script>

<style scoped>

</style>